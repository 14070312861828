import React, { createContext, useContext, useState, useRef, useEffect, useReducer } from "react";
import { detectUserAgent, detectWindowWidth, getLoginInfo } from "../utils/commonFunctions";
import { historyReducer } from "../reducer";

export const CommonContext = createContext();

export const CommonProvider = ({ children }) => {
  const [user, setUser] = useState({
    user_id: '',
    is_admin: false,
    access_token: '',
    token_type: '',
  });
  // 초기 상태
  const initialState = [];
  const [isLogin, setIsLogin] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isGuide, setIsGuide] = useState(false);
  const [isOpenStates, setIsOpenStates] = useState([]);  // 메뉴 상태
  const controllerRef = useRef(new AbortController());
  const [isUpdate, setIsUpdate] = useState(false);
  const [file, setFile] = useState(null);
  // const [history, setHistory] = useState([]);
  const [history, dispatch] = useReducer(historyReducer, initialState) 
  const [openNav, setOpenNav] = useState(true);

  const fileRef = useRef(null); // input창의 file 초기화용 ref
  // Toggle Menu Open/Close
  const toggleOpen = (index) => {
    const newIsOpenStates = [...isOpenStates];
    newIsOpenStates[index].isOpen = !newIsOpenStates[index].isOpen;
    setIsOpenStates(newIsOpenStates);
  };

  // 사이드바 열기/닫기
  const toggleNav = () => {
    setOpenNav(!openNav);
  };

  // User 정보 업데이트
  const updateUserInfo = () => {
    if (user.user_id !== '') {
      getLoginInfo(setUser);
    } else {
      setIsLogin(false);
    }
  };

  // 화면 크기 조정
  const setScreenSize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    setScreenSize();
    const checkMobile = detectWindowWidth();
    setIsMobile(checkMobile);
  }, []);

  // 화면 리사이즈 감지
  const handleResize = () => {
    updateUserInfo();
    const checkAgent = detectUserAgent();
    setIsMobile(checkAgent === 'mobile' ? true : false);
  };
  const resetFile = () =>{
    setFile(null)
    if(fileRef.current) {
      fileRef.current.value = ""
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <CommonContext.Provider
      value={{
        user,
        setUser,
        isOpenStates,
        isGuide,
        setIsGuide,
        toggleOpen,
        history,
        dispatch,
        openNav,
        toggleNav,
        isUpdate,
        setIsUpdate,
        isMobile,
        controllerRef,
        file,
        setFile,
        resetFile,
        fileRef
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

export const useCommonContext = () => {
  return useContext(CommonContext);
};
