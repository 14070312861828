import CryptoJS from "crypto-js";
import { unified } from "unified";
import markdown from 'remark-parse';
import remark2rehype from "remark-rehype";
import html from "rehype-stringify";
import gfm from 'remark-gfm';

export const toggleOpen = (isOpen, setIsOpen, index) => {
  const newIsOpen = [...isOpen];
  newIsOpen[index] = !newIsOpen[index];
  setIsOpen(newIsOpen)
}

export const generateRandomString = (number) => {
  const char = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let result = '';
  const charLenght = char.length;
  for (let i = 0; i < number; i++) {
    result += char.charAt(Math.floor(Math.random() * charLenght));
  }
  return result;
}

export const checkNumberType = (value) => {

  const isNumeric = /^\d+$/.test(value);
  if (!isNumeric) {
    return false
  } else {
    return true
  }
}

export const convertKeysToKorean = (obj) => {
  const koreanMapping = new Map([
    ['address', '주소'],
    ['area', '지역'],
    ['close', '휴관일'],
    ['contact', '연락처'],
    ['contact_name', '담당자'],
    ['description', '체험 내용'],
    ['duration', '소요 시간'],
    ['facilities', '시설'],
    // ['id', 'ID'],
    ['name', '체험명'],
    ['open', '영업 시간'],
    ['price', '가격'],
    ['programs', '프로그램'],
    ['subject', '과목'],
    ['target', '체험학습대상'],
    ['url', '홈페이지'],
  ]);

  const convertedObj = {};
  for (const [key, value] of Object.entries(obj)) {
    const koreanKey = koreanMapping.get(key) || key;
    convertedObj[koreanKey] = value;
  }

  return convertedObj;
}
export const convertTxtToKorean = (key) => {
  const koreanMapping =
  {
    link: '페이지 주소',
    title: '검색어',
    address: '주소',
    area: '지역',
    close: '휴관일',
    contact: '연락처',
    contact_name: '담당자',
    description: '상세 내용',
    duration: '소요 시간',
    facilities: '시설',
    id: 'ID',
    name: '체험명',
    open: '영업 시간',
    price: '가격',
    programs: '프로그램',
    subject: '과목',
    target: '체험학습대상',
    url: '홈페이지',
    doc_name: '서식명',
    download: '서식',
    group: '그룹1',
    main_ref: '출처',
    subgroup: '그룹2'
  }

  let keyWord = koreanMapping[key]
  return keyWord;
}

export const convertToHtml = string => {
  const html_text = unified()
    .use(markdown)
    .use(gfm)
    .use(remark2rehype)
    .use(html)
    .processSync(string);

  return <div className="convert-answer"><div dangerouslySetInnerHTML={{ __html: html_text.value }} key={string.length} /></div>;
}

export const fetchInstance = async (url, formData, controller, stream) => {
  // const timeoutId = setTimeout(() => controller.abort(), timeout);

  const payloadConfig = {
    engine: "curie",
    // prompt: text,
    temperature: 0.75,
    top_p: 0.95,
    max_tokens: 3,
    stream: true,
    stop: ["\n\n"],
  }
  const config = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "accept": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(formData),
    payload: stream ? JSON.stringify(payloadConfig) : null,
    signal: controller?.signal
  };
  try {
    const response = await fetch(url, config);
    // clearTimeout(timeoutId);

    if (!response.ok) {
      throw new Error('Network response was not ok');//no response
    }

    // response를 받은 후에도 controller.abort()를 호출하여 확실하게 통신을 중단합니다.

    const result = await response.json();
    return result;

  } catch (error) {
    if (error.name === 'AbortError') {
      return error.name
    }
    return error; // 또는 오류 처리에 맞게 수정
  }
};

export const backIncstance = async (url, data, token) => {
  // token 없을 때 
  let isLogin = token === undefined ? true : false
  let config = {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      'Content-Type': token ? 'application/json' : 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*'
    },
    body: isLogin ? data : JSON.stringify(data),
    // body: data,
  }
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  try {
    const response = await fetch(url, config);

    // if (!response.ok) {
    //   throw new Error('Network response was not ok');
    // }

    const result = await response.json();
    return result;
  } catch (error) {
    return error; // 또는 오류 처리에 맞게 수정
  }
}

export const copyToClipboard = item => {
  alert('클립보드에 내용이 복사되었습니다.')
}

export const countVisitPerDay = item => {
  const now = new Date();
  const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const visit = JSON.parse(sessionStorage.getItem('visit'));

  if (now > midnight) {
    sessionStorage.removeItem('visit');
    sessionStorage.setItem('visit', JSON.stringify(item));
  } else {
    sessionStorage.setItem('visit', JSON.stringify(item));
  }
}

export const sendVisitorCount = url => {
  let tday = new Date();
  let now = tday.getHours() + tday.getTime();
  let item = {
    value: now
  }
  countVisitPerDay(item)
  backIncstance(url, undefined)
    .then((res) => {
      // console.log(res)
    })
    .catch((err) => {
      // console.log(err)
    })

  // 0시 기준으로 session에 저장하여
  // const value = sessionStorage.getItem('visit');

  // if (!value) {
  //   let tday = new Date();
  //   let now = tday.getHours() + tday.getTime();
  //   let item = {
  //     value: now
  //   }
  //   countVisitPerDay(item)
  //   backIncstance(VISIT_URL, undefined)
  //     .then((res) => {
  //     // console.log(res)
  //     })
  //     .catch((err) => {
  //     // console.log(err)
  //   })
  // } 

}
export const getLoginInfo = (setData) => {
  let data = sessionStorage.getItem('loginInfo')
  if (data) {
    const decryptedData = decryptData(data)
    setData(decryptedData)
  }
}
export const checkPwValidation = password => {

  let num = password.search(/[0-9]/g);
  let eng = password.search(/[a-z]/ig);
  let space = password.search(/\s/);

  let error = ''
  if (password.length < 8 || password.length > 20) {
    error = '비밀번호는 8자리~20자리 이내로 입력해주세요.'
  } else if (space !== -1) {
    error = '비밀번호는 공백이 포함될 수 없습니다.'
  } else if (num < 0 || eng < 0) {
    error = '영문, 숫자를 혼합하여 입력해주세요.'
  }
  return error === '' ? 'success' : error
}
export const encryptoData = (text) => {
  const jsonData = JSON.stringify(text)
  const data = CryptoJS.AES.encrypt(jsonData, process.env.REACT_APP_SECRET_KEY).toString();
  return data;
}

export const decryptData = (text) => {
  const bytes = CryptoJS.AES.decrypt(text, process.env.REACT_APP_SECRET_KEY);
  const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
  const decryptedData = JSON.parse(decryptedString);
  return decryptedData;
}

export const detectWindowWidth = () => {
  const viewportWidth = window.innerWidth;
  let isMobile = false;
  if (viewportWidth < 769) {
    isMobile = true
  } else {
    isMobile = false
  }
  return isMobile
};
export const detectUserAgent = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  let agent = '';

  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      userAgent.substr(0, 4)
    )
  ) {
    // 모바일용 CSS 파일 경로
    agent = 'mobile'
  } else {
    // PC용 CSS 파일 경로
    agent = 'pc'
  }
  return agent
}

export const mobileScreenSize = () => {
  let vh = window.innerHeight * 0.01;
  return vh;
}
export function validateFile(file) {

  const MAX_FILE_SIZE = 10 * 1024 * 1024; 
  // 허용하는 파일 형식 목록
  const ALLOWED_FILE_TYPES = [
    "pdf",
    "docx",
    "hwp",
    "txt",
  ];

  const fileExtension = file.name.split(".").pop().toLowerCase(); // 확장자 추출
  let text;
  // 파일 형식 검사
  if (!ALLOWED_FILE_TYPES.includes(fileExtension)) {
    text = "허용되지 않는 파일 형식입니다."
  }
  // 파일 크기 검사
  if (file.size > MAX_FILE_SIZE) {
      text = `파일 크기가 너무 큽니다. 최대 ${MAX_FILE_SIZE / (1024 * 1024)}MB까지 업로드 가능합니다.`
    }

  return text;
}