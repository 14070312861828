export function historyReducer(state, action) {
  const { type, payload, index } = action;
  const updatedState = [...state];

  switch (type) {
    case 'SEND_QUESTION' : {
        return [...state, payload.conversation];
    } 
    case 'GET_ANSWER' : {
      const { myIdx, jsonData, url, NAVER_URL } = action.payload;
      const newState = [...state];
      const newAnswer = newState[myIdx]?.answer !== undefined 
        ? newState[myIdx].answer + jsonData.content 
        : jsonData?.content;
      newState[myIdx] = {
        ...newState[myIdx],
        answer: newAnswer,
        action: jsonData?.action,
        last_result: jsonData?.last_result,
        naversearch: (url === NAVER_URL),
        comment: ''
      };

      return newState;
    }
    case 'REGEX_MATCH': {
      updatedState[index] = {
        ...updatedState[index],
        id: payload.response_id,
        last_result: payload.last_result,
        latency: payload.latency,
        naversearch: true,
        maps: false,
        aborted: false,
      };
      return updatedState;
    }
    case 'FORBIDDEN': {
      updatedState[index] = {
        ...updatedState[index],
        id: payload.response_id,
        last_result: payload.last_result,
        rec: payload.recommended_questions,
        latency: payload.latency,
        reference: payload.reference,
        sub_action: payload.sub_action,
        naversearch: false,
        search_result: payload.search_result,
        action: payload.action,
        answer: payload.response,
        maps: false,
      };
      return updatedState;
    }
    case 'TEMPLATE': {
      updatedState[index] = {
        ...updatedState[index],
        id: payload.response_id,
        last_result: payload.last_result,
        rec: payload.recommended_questions,
        latency: payload.latency,
        action: payload.action,
        reference: payload.reference,
        answer: payload.response,
        naversearch: false,
        search_result: payload.search_result,
        type: payload.action,
        maps: false,
        comment: '',
      };
      return updatedState;
    }
    case 'PROGRAM': {
      updatedState[index] = {
        ...updatedState[index],
        id: payload.response_id,
        last_result: payload.last_result,
        rec: payload.recommended_questions,
        latency: payload.latency,
        action: payload.action,
        reference: payload.reference,
        answer: payload.response,
        naversearch: false,
        search_result: payload.search_result,
        type: payload.action,
        maps: false,
        comment: '',
      };
      return updatedState;
    }
    case 'NAVER_SEARCH': {
      updatedState[index] = {
        ...updatedState[index],
        answer: '',
      };
      return updatedState;
    }
    case 'DELIVER_FEEDBACK': {
      updatedState[index] = {
        ...updatedState[index],
        comment: payload.comment, // 코멘트를 업데이트
      };
      return updatedState;
    }
    case 'DELIVER_REACTION': {
        const targetIdx = updatedState.findIndex((chat) => chat.id === payload.id);
      if (targetIdx !== -1) {
        if (updatedState[targetIdx].reaction === Number(payload.target)) {
          updatedState[targetIdx].reaction = null;
          updatedState[targetIdx].evaluation = null; // 평가 초기화
        } else {
          updatedState[targetIdx].reaction = Number(payload.target);
        }
      }
      return updatedState;

    }
    case 'ABORT_ERROR': {
        updatedState[index] = {
            ...updatedState[index],
            aborted: true,
            last_result: true,
        }
        return updatedState;
    }
    case 'CLEAR': {
        return []
    }
    default: {
      updatedState[index] = {
        ...updatedState[index],
        id: payload.response_id,
        last_result: payload.last_result,
        rec: payload.recommended_questions,
        latency: payload.latency,
        reference: payload.reference,
        sub_action: payload.sub_action,
        naversearch: false,
        search_result: payload.search_result,
        type: payload.action,
        maps: false,
        comment: '',
      };
      return updatedState;
    }
  }
}
