import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Suspense, useEffect, useRef, useState } from "react";
import './App.css';
import routes from "./routes";
import { NavermapsProvider } from "react-naver-maps";
// context
import {  CommonProvider, useCommonContext } from "./context/CommonContext";

// component
import Loading from "./components/Loading";
import NotFound from "./views/common/NotFound";

// utils
import { detectUserAgent, detectWindowWidth, getLoginInfo } from "./utils/commonFunctions";

const ADMIN_URL = 'admin';
const MAP_KEY = process.env.REACT_APP_MAP_PUB_KEY;

function App() {

  const navigation = useNavigate();
const { user, setUser, history } = useCommonContext();

   const [isMobile, setIsMobile] = useState(false)
  const [isLogin, setIsLogin] = useState(false);

  // 어드민 Nav, 프롬프트 SideBar 사용
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname.includes(ADMIN_URL)) {
      setIsAdmin(true)
    }
    return () => {
      setIsAdmin(false);
    }

  }, [])


  useEffect(() => {
    if (user.user_id !== '') {
      getLoginInfo(setUser)
    } else {
      setIsLogin(false)
    }

  }, [isAdmin, navigation])


  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }
  useEffect(() => {
    setScreenSize();
    const checkMobile = detectWindowWidth();
    setIsMobile(checkMobile)
  }, []);

  const handleResize = () => {

    getLoginInfo(setUser)
    const checkAgent = detectUserAgent();
    const scrollHeight = document.body.scrollHeight;

    setIsMobile(checkAgent === 'mobile' ? true : false)
    return () => {
      setUser({
        user_id: '',
        is_admin: false,
        access_token: '',
        token_type: '',
        group_auth_name: '',
        group_id: -1
      })
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {

    document.body.style.height = '100vh';
    document.documentElement.style.height = '100vh';

    const rootElement = document.getElementById('root');
    const boxElement = document.querySelector('.chat-prompt__box__history');
    const mainElement = document.querySelector('main');

    if (rootElement && boxElement && mainElement) {
      rootElement.style.height = isMobile ? '100vh' : '';
      boxElement.style.height = isMobile ? 'calc(var(--vh, 1vh) * 100)' : '100vh';
      mainElement.style.height = isMobile ? '100vh' : '100vh';
      // mainElement.style.height = isMobile ? 'calc(var(--vh, 1vh) * 100)' : '100vh';
    }
  }, [isMobile, history.length]);

   return (
    <NavermapsProvider ncpClientId={MAP_KEY}>
      <Suspense fallback={<Loading />}>
        <Routes>
          {routes.map((each, index) => (
            <Route
              key={`route-${index}`}
              path={each.path}
              Component={each.element}
            />
          ))}
          <Route path="*" Component={NotFound} />
        </Routes>
      </Suspense>
    </NavermapsProvider>
  );
}

export default function AppWrapper() {
  return (
    <CommonProvider>
      <App />
    </CommonProvider>
  );
}